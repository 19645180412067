import { render, staticRenderFns } from "./FooterSeoLinks.vue?vue&type=template&id=23af1e10&scoped=true"
import script from "./FooterSeoLinks.vue?vue&type=script&setup=true&lang=ts"
export * from "./FooterSeoLinks.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./FooterSeoLinks.vue?vue&type=style&index=0&id=23af1e10&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23af1e10",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Translate: require('/app/components/Translate.vue').default,PuLink: require('/app/apps/pu-links/pu-link/PuLink.vue').default})
