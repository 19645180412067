import { render, staticRenderFns } from "./HomepageMain.vue?vue&type=template&id=3d7edd18&scoped=true"
import script from "./HomepageMain.vue?vue&type=script&lang=ts"
export * from "./HomepageMain.vue?vue&type=script&lang=ts"
import style0 from "./HomepageMain.vue?vue&type=style&index=0&id=3d7edd18&prod&lang=css"
import style1 from "./HomepageMain.vue?vue&type=style&index=1&id=3d7edd18&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d7edd18",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Translate: require('/app/components/Translate.vue').default,PopularSearches: require('/app/components/homepage/PopularSearches.vue').default,TopDestinations: require('/app/components/homepage/TopDestinations.vue').default,AccommodationTypes: require('/app/components/homepage/AccommodationTypes.vue').default,PopularFeatures: require('/app/components/homepage/PopularFeatures.vue').default,NationalParks: require('/app/apps/national-parks/NationalParks.vue').default,PuLink: require('/app/apps/pu-links/pu-link/PuLink.vue').default,PuSearchLink: require('/app/apps/pu-links/link-search/PuSearchLink.vue').default,ArticleList: require('/app/components/homepage/ArticleList.vue').default,CampsiteOwner: require('/app/components/homepage/CampsiteOwner.vue').default,AboutPitchup: require('/app/components/homepage/AboutPitchup.vue').default,FooterSeoLinks: require('/app/apps/footer-seo-links/FooterSeoLinks.vue').default})
