
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'

interface Card {
  name: string
  to: { facet?: string; category: string }
  imgUrl: string
  iconUrl?: string
}

@Component
export default class HorizontalCardSlider extends Vue {
  @Prop({ required: true })
    cards: Card[]

  @Prop({ required: false })
    excludeType: string

  get columnCount() {
    return Math.round(this.cards.length / 2)
  }
}
