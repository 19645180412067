import * as z from 'zod'
import { langFallback$Fetch } from '../../lang/utils/langfetch'

export const footerSeoLinksSchema = z
  .object({
    title: z.string(),
    destinations: z
      .object({
        name: z.string(),
        url: z.string(),
      })
      .array(),
  })
  .array()

export type FooterSeoLinksData = z.infer<typeof footerSeoLinksSchema>

const _footers = {}

export async function getFooterSeoLinks(
  langCode: string,
): Promise<FooterSeoLinksData | null> {
  if (_footers[langCode]) return _footers[langCode]
  const results = await langFallback$Fetch(langCode, '/_/seo-footer/')
  _footers[langCode] = footerSeoLinksSchema.parse(results) || null
  return _footers[langCode]
}
