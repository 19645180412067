import { injectable } from '~/container'
import { TopDestination, topDestinationSchema } from './schemas'

const _topDestinations = {}

@injectable()
export class AjaxFetchTopDestinationsRepository {
  async getAll(lang: string, fetchOptions): Promise<TopDestination[]> {
    if (_topDestinations[lang]) return _topDestinations[lang]
    const destinations = await $fetch<PopularLocationResponse[]>(
      this.getUrl(lang),
      fetchOptions,
    )
    _topDestinations[lang] = destinations.map((destination) => {
      const result = topDestinationSchema.safeParse(destination)
      if (!result.success) {
        const formatted = result.error.flatten()
        // eslint-disable-next-line no-console
        console.error(
          "Couldn't parse top destination",
          JSON.stringify(formatted),
          JSON.stringify(destination),
        )
        // use as is
        return destination
      }
      return result.data
    })
    return _topDestinations[lang]
  }

  private getUrl(lang: string) {
    const langPrefix = lang !== 'en-gb' ? `${lang}/` : ''
    return `/_/fallback/${langPrefix}_/endpoints/popular-locations/`
  }
}

interface PopularLocationResponse {
  additionalDisplayName?: string
  count: number
  hierarchy: string
  name: string
  imageUrl: string
}
