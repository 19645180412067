import * as z from 'zod'
import { getTransformObject } from '~/shared/photos/transforms'

export const topDestinationSchema = z.object({
  additionalDisplayName: z.string().optional(),
  count: z.number(),
  hierarchy: z.string(),
  name: z.string(),
  imageUrl: z
    .string()
    .transform(
      (url) =>
        getTransformObject<{ image: string }>('topDestinations', url).image,
    ),
})

export type TopDestination = z.infer<typeof topDestinationSchema>
