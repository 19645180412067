
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'
import type { CampsiteCategory } from '~/utility/types/CampsiteCategory'
import { getCampsiteCategoriesPresenter } from '~~/apps/categories/presenter-json/CampsiteCategoriesUpdaterJson'

interface HorizontalCard {
  name: string
  to: { facet?: string; category: string }
  imgUrl?: string
  iconUrl?: string
}

@Component
export default class HomepageMain extends Vue {
  @Prop()
    popularFeaturesData: HorizontalCard[]

  get shouldShowArticles() {
    return this.$i18n.locale.startsWith('en-')
  }

  get accomodationCategoriesData(): HorizontalCard[] {
    const categoriesPresenter = getCampsiteCategoriesPresenter(
      this.$route.params.lang || 'en-gb',
    )
    const categories = categoriesPresenter.bySlugMap
    const sliderData: HorizontalCard[] = []
    for (const [index, [_, category]] of Object.entries(categories).entries()) {
      // glamping is still not a proper category so need to push it based on the required index
      if (index === 4) {
        sliderData.push(this.glampingCategory)
      }
      if (category.id.toString() != '13') {
        sliderData.push(this.getCategorySliderData(category))
      }
    }
    return sliderData
  }

  private getCategorySliderData(category: CampsiteCategory) {
    return {
      name: category.pretty_name,
      to: { category: category.rootSlug },
      iconUrl: require(`@/assets/images/category-icons/${category.slug}.svg`),
    }
  }

  private get glampingCategory(): HorizontalCard {
    return {
      name: this.$t('Glamping') as string,
      to: { category: 'glamping' },
      iconUrl: require('@/assets/images/category-icons/glamping.svg'),
    }
  }

  activeItem = 'popular'

  isActive(menuItem: string) {
    return this.activeItem === menuItem
  }

  setActive(menuItem: string) {
    this.activeItem = menuItem
  }

  getImageUrl(imageName: string) {
    return require(`@/assets/homepage/${imageName}.jpg`)
  }
}
